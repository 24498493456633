import common_en from "../locales/common/en_US.json";
import common_pl from "../locales/common/pl_PL.json";
import en from "../locales/en.json";
import pl from "../locales/pl.json";

export const supportedLanguages = {
  AFRIKAANS_SOUTH_AFRICA: {
    nameTKey: "str_language_afrikaans",
    originalName: "Afrikaans",
    flagName: "Afrikaans",
    apiKey: "af",
    bcp47: "af-ZA",
  },
  ARABIC_SAUDI_ARABIA: {
    nameTKey: "str_language_arabic",
    originalName: "العربية",
    flagName: "Arabic",
    apiKey: "ar",
    bcp47: "ar-XA",
  },
  BENGALI_INDIA: {
    nameTKey: "str_language_bengali",
    originalName: "Bengali",
    flagName: "Bengali",
    apiKey: "bn",
    bcp47: "bn-IN",
  },
  BULGARIAN_BULGARIA: {
    nameTKey: "str_language_bulgarian",
    originalName: "Български",
    flagName: "Bulgarian",
    apiKey: "bg",
    bcp47: "bg-BG",
  },
  CATALAN_SPAIN: {
    nameTKey: "str_language_catalan",
    originalName: "Català",
    flagName: "Catalan",
    apiKey: "ca",
    bcp47: "ca-ES",
  },
  CHINESE_MANDARIN_SIMPLIFIED_HONG_KONG: {
    nameTKey: "str_language_chinese_simplified",
    originalName: "中文(简体字)",
    flagName: "ChineseSimplified",
    apiKey: "zh-CHS",
    bcp47: "zh-Hans-HK",
  },
  CHINESE_MANDARIN_TRADITIONAL_TAIWAN: {
    nameTKey: "str_language_chinese_traditional",
    originalName: "中文(台湾普通话)",
    flagName: "ChineseSimplified",
    apiKey: "zh-CHT",
    bcp47: "zh-Hant-TW",
  },
  CHINESE_CANTONESE_TRADITIONAL_HONG_KONG: {
    nameTKey: "str_language_chinese_cantonese",
    originalName: "中文(粤语)",
    flagName: "ChineseSimplified",
    apiKey: "yue",
    bcp47: "yue-HK",
  },
  CZECH_CZECH_REPUBLIC: {
    nameTKey: "str_language_czech",
    originalName: "Čeština",
    flagName: "Czech",
    apiKey: "cs",
    bcp47: "cs-CZ",
  },
  CROATIAN_CROATIA: {
    nameTKey: "str_language_croatian",
    originalName: "Hrvatski",
    flagName: "Croatian",
    apiKey: "hr",
    bcp47: "hr-HR",
  },
  DANISH_DENMARK: {
    nameTKey: "str_language_danish",
    originalName: "Dansk",
    flagName: "Danish",
    apiKey: "da",
    bcp47: "da-DK",
  },
  DUTCH_NETHERLANDS: {
    nameTKey: "str_language_dutch",
    originalName: "Nederlands",
    flagName: "Dutch",
    apiKey: "nl",
    bcp47: "nl-NL",
  },
  ENGLISH_UNITED_STATES: {
    nameTKey: "str_language_english_us",
    originalName: "English (United States)",
    flagName: "EnglishUSA",
    apiKey: "en",
    bcp47: "en-US",
  },
  ENGLISH_AUSTRALIA: {
    nameTKey: "str_language_english_australia",
    originalName: "English (Australia)",
    flagName: "EnglishAustralia",
    apiKey: "en",
    bcp47: "en-AU",
  },
  ENGLISH_UNITED_KINGDOM: {
    nameTKey: "str_language_english_gb",
    originalName: "English (United Kingdom)",
    flagName: "EnglishUK",
    apiKey: "en",
    bcp47: "en-GB",
  },
  ENGLISH_INDIA: {
    nameTKey: "str_language_english_india",
    originalName: "English (India)",
    flagName: "EnglishIndia",
    apiKey: "en",
    bcp47: "en-IN",
  },
  FILIPINO_PHILIPPINES: {
    nameTKey: "str_language_filipino",
    originalName: "Filipino",
    flagName: "Filipino",
    apiKey: "fil",
    bcp47: "fil-PH",
  },
  FINNISH_FINLAND: {
    nameTKey: "str_language_finnish",
    originalName: "Suomi",
    flagName: "Finnish",
    apiKey: "fi",
    bcp47: "fi-FI",
  },
  FRENCH_FRANCE: {
    nameTKey: "str_language_french_fr",
    originalName: "Français (France)",
    flagName: "FrenchFrance",
    apiKey: "fr",
    bcp47: "fr-FR",
  },
  FRENCH_CANADA: {
    nameTKey: "str_language_french_ca",
    originalName: "French (Canada)",
    flagName: "FrenchCanada",
    apiKey: "fr",
    bcp47: "fr-CA",
  },
  GERMAN_GERMANY: {
    nameTKey: "str_language_german",
    originalName: "Deutsch",
    flagName: "German",
    apiKey: "de",
    bcp47: "de-DE",
  },
  GREEK_GREECE: {
    nameTKey: "str_language_greek",
    originalName: "Ελληνικά",
    flagName: "Greek",
    apiKey: "el",
    bcp47: "el-GR",
  },
  GUJARATI_INDIA: {
    nameTKey: "str_language_gujarati",
    originalName: "Gujarati",
    flagName: "Gujarati",
    apiKey: "gu",
    bcp47: "gu-IN",
  },
  HINDI_INDIA: {
    nameTKey: "str_language_hindi",
    originalName: "हिन्दी",
    flagName: "Hindi",
    apiKey: "hi",
    bcp47: "hi-IN",
  },
  HUNGARIAN_HUNGARY: {
    nameTKey: "str_language_hungarian",
    originalName: "Magyar",
    flagName: "Hungarian",
    apiKey: "hu",
    bcp47: "hu-HU",
  },
  ICELANDIC_ICELAND: {
    nameTKey: "str_language_icelandic",
    originalName: "Icelandic",
    flagName: "Icelandic",
    apiKey: "is",
    bcp47: "is-IS",
  },
  INDONESIAN_INDONESIA: {
    nameTKey: "str_language_indonesian",
    originalName: "Bahasa Indonesia",
    flagName: "Indonesian",
    apiKey: "id",
    bcp47: "id-ID",
  },
  ITALIAN_ITALY: {
    nameTKey: "str_language_italian",
    originalName: "Italiano",
    flagName: "Italian",
    apiKey: "it",
    bcp47: "it-IT",
  },
  JAPANESE_JAPAN: {
    nameTKey: "str_language_japanese",
    originalName: "日本語",
    flagName: "Japanese",
    apiKey: "ja",
    bcp47: "ja-JP",
  },
  KANNADA_INDIA: {
    nameTKey: "str_language_kannada",
    originalName: "Kannada",
    flagName: "Kannada",
    apiKey: "kn",
    bcp47: "kn-IN",
  },
  KOREAN_SOUTH_KOREA: {
    nameTKey: "str_language_korean",
    originalName: "한국어",
    flagName: "Korean",
    apiKey: "ko",
    bcp47: "ko-KR",
  },
  LATVIAN_LATVIA: {
    nameTKey: "str_language_latvian",
    originalName: "Latviešu",
    flagName: "Latvian",
    apiKey: "lv",
    bcp47: "lv-LV",
  },
  MALAY_MALAYSIA: {
    nameTKey: "str_language_malay",
    originalName: "Bahasa Melayu",
    flagName: "Malay",
    apiKey: "ms",
    bcp47: "ms-MY",
  },
  MALAYALAM_INDIA: {
    nameTKey: "str_language_malayalam",
    originalName: "Malayalam",
    flagName: "Malayalam",
    apiKey: "ml",
    bcp47: "ml-IN",
  },
  NORWEGIAN_BOKMAL_NORWAY: {
    nameTKey: "str_language_norwegian",
    originalName: "Norsk",
    flagName: "Norwegian",
    apiKey: "no",
    bcp47: "nb-NO",
  },
  POLISH_POLAND: {
    nameTKey: "str_language_polish",
    originalName: "Polski",
    flagName: "Polish",
    apiKey: "pl",
    bcp47: "pl-PL",
  },
  PORTUGUESE_BRAZIL: {
    nameTKey: "str_language_portuguese_br",
    originalName: "Português (Brazil)",
    flagName: "PortugueseBrazil",
    apiKey: "pt",
    bcp47: "pt-BR",
  },
  PORTUGUESE_PORTUGAL: {
    nameTKey: "str_language_portuguese_pt",
    originalName: "Português (Portugal)",
    flagName: "PortuguesePortugal",
    apiKey: "pt-pt",
    bcp47: "pt-PT",
  },
  PUNJABI_GURMUKHI_INDIA: {
    nameTKey: "str_language_punjabi",
    originalName: "Punjabi",
    flagName: "Punjabi",
    apiKey: "pa",
    bcp47: "pa-IN",
  },
  ROMANIAN_ROMANIA: {
    nameTKey: "str_language_romanian",
    originalName: "Română",
    flagName: "Romanian",
    apiKey: "ro",
    bcp47: "ro-RO",
  },
  RUSSIAN_RUSSIA: {
    nameTKey: "str_language_russian",
    originalName: "Русский",
    flagName: "Russian",
    apiKey: "ru",
    bcp47: "ru-RU",
  },
  SERBIAN_SERBIA: {
    nameTKey: "str_language_serbian",
    originalName: "Srpski",
    flagName: "Serbian",
    apiKey: "sr-Latn",
    bcp47: "sr-Latn-RS",
  },
  SLOVAK_SLOVAKIA: {
    nameTKey: "str_language_slovak",
    originalName: "Slovenský",
    flagName: "Slovak",
    apiKey: "sk",
    bcp47: "sk-SK",
  },
  SPANISH_SPAIN: {
    nameTKey: "str_language_spanish_es",
    originalName: "Español (España)",
    flagName: "SpanishSpain",
    apiKey: "es",
    bcp47: "es-ES",
  },
  SPANISH_UNITED_STATES: {
    nameTKey: "str_language_spanish_am",
    originalName: "Spanish (America)",
    flagName: "SpanishAmerica",
    apiKey: "es",
    bcp47: "es-US",
  },
  SWEDISH_SWEDEN: {
    nameTKey: "str_language_swedish",
    originalName: "Svenska",
    flagName: "Swedish",
    apiKey: "sv",
    bcp47: "sv-SE",
  },
  TAMIL_INDIA: {
    nameTKey: "str_language_tamil",
    originalName: "Tamil",
    flagName: "Tamil",
    apiKey: "ta",
    bcp47: "ta-IN",
  },
  THAI_THAILAND: {
    nameTKey: "str_language_thai",
    originalName: "Thai",
    flagName: "Thai",
    apiKey: "th",
    bcp47: "th-TH",
  },
  TURKISH_TURKEY: {
    nameTKey: "str_language_turkish",
    originalName: "Türkçe",
    flagName: "Turkish",
    apiKey: "tr",
    bcp47: "tr-TR",
  },
  UKRAINIAN_UKRAINE: {
    nameTKey: "str_language_ukrainian",
    originalName: "Українська",
    flagName: "Ukrainian",
    apiKey: "uk",
    bcp47: "uk-UA",
  },
  VIETNAMESE_VIETNAM: {
    nameTKey: "str_language_vietnamese",
    originalName: "Tiếng Việt",
    flagName: "Vietnamese",
    apiKey: "vi",
    bcp47: "vi-VN",
  },
} as const;

export const resources = {
  en: { translation: { ...common_en, ...en } },
  pl: { translation: { ...common_pl, ...pl } },
};

export type SupportedLanguageName = keyof typeof supportedLanguages;

export type FlagTranslationKeys = (typeof supportedLanguages)[keyof typeof supportedLanguages]["nameTKey"];

export type SupportedLanguagesBcp47 = (typeof supportedLanguages)[keyof typeof supportedLanguages]["bcp47"];

export type SupportedLanguageKeys = (typeof supportedLanguages)[keyof typeof supportedLanguages]["apiKey"];

export type SupportedLanguage = (typeof supportedLanguages)[keyof typeof supportedLanguages];

export type SupportedLanguageWithName = SupportedLanguage & { languageName: SupportedLanguageName };
